export const MailIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
    )
}

export const ClockIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
        </svg>
    )
}

export const MoneyIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <line x1="12" y1="1" x2="12" y2="23"></line>
            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
    )
}

export const ChartUpIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M3 3v18h18" />
            <path d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3" />
        </svg>
    )
}

export const AudienceIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
        </svg>
    )
}

export const XIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    )
}

export const SendIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3" />
        </svg>
    )
}

export const ChatIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
        </svg>
    )
}

export const CheckIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <div style={{ minWidth: width, marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round">
                <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
        </div>
    )
}

export const CheckCircleIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
    )
}

export const ChevronUpIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M18 15l-6-6-6 6" />
        </svg>
    )
}

export const ChevronDownIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M6 9l6 6 6-6" />
        </svg>
    )
}

export const ChevronRightIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M9 18l6-6-6-6" />
        </svg>
    )
}

export const ChevronLeftIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M15 18l-6-6 6-6" />
        </svg>
    )
}


export const WarningIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>
    )
}

export const ProfileIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3" />
            <circle cx="12" cy="10" r="3" />
            <circle cx="12" cy="12" r="10" />
        </svg>
    )
}

export const ArrowRightIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M5 12h13" />
            <path d="M12 5l7 7-7 7" />
        </svg>
    )
}

export const ArrowLeftIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M19 12H6" />
            <path d="M12 19l-7-7 7-7" />
        </svg>
    )
}

export const CartIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <circle cx="10" cy="20.5" r="1" />
            <circle cx="18" cy="20.5" r="1" />
            <path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1" />
        </svg>
    )
}

export const ShoppingBagIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6z" />
            <path d="M3.8 6h16.4" />
            <path d="M16 10a4 4 0 1 1-8 0" />
        </svg>
    )
}

export const VerticalDotsIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <circle cx="12" cy="12" r="1"></circle>
            <circle cx="12" cy="5" r="1"></circle>
            <circle cx="12" cy="19" r="1"></circle>
        </svg>
    )
}

export const EyeIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
        </svg>
    )
}

export const VideoIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <path d="M15.6 11.6L22 7v10l-6.4-4.5v-1z"></path>
            <path d="M4 5h9a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2z"></path>
        </svg>
    )
}

export const SearchIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}
        >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
    )
}

export const CommentIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}
        >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
        </svg>
    )
}

export const CouponIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}
        >
            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
            <line x1="7" y1="7" x2="7.01" y2="7"></line>
        </svg>
    )
}

export const PersonIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill={color}
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="flat"
            strokeLinejoin="flat"
        >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
        </svg>
    );
}

export const CorporateIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 512 512"
        fill={color} // directly using the color prop for fill
    >
        <g strokeWidth="0">
            <path
                d="M256.494,96.433c26.632,0,48.209-21.592,48.209-48.201C304.703,21.584,283.126,0,256.494,0 c-26.647,0-48.216,21.584-48.216,48.232C208.278,74.84,229.847,96.433,256.494,96.433z"
            />
            <path
                d="M321.225,126.746l-64.731,64.731l-65.139-65.131c-14.756,9.176-26.412,25.91-26.412,41.718v162.059 c0,11.687,9.466,21.153,21.153,21.153c5.738,0,0,0,14.757,0l8.045,138.214c0,12.433,10.078,22.511,22.519,22.511 c5.236,0,14.92,0,24.583,0c9.67,0,19.34,0,24.591,0c12.432,0,22.503-10.078,22.503-22.511l8.052-138.214c14.757,0,9.003,0,14.757,0 c11.679,0,21.145-9.466,21.145-21.153V168.063C347.049,152.475,335.715,136,321.225,126.746z"
                fill={color} // using the color prop
            />
            <polygon
                points="242.076,165.732 256.463,180.119 269.807,166.784 269.807,140.82 242.076,140.82 "
                fill={color} // using the color prop
            />
            <rect x="242.091" y="113.787" width="27.691" height="17.747" fill={color} />
            <path
                d="M408.711,149.084c23.28,0,42.102-18.854,42.102-42.11c0-23.256-18.822-42.11-42.102-42.11 c-23.249,0-42.094,18.853-42.094,42.11C366.617,130.231,385.462,149.084,408.711,149.084z"
                fill={color}
            />
            <path
                d="M458.065,171.784l-50.202,50.194l-33.123-33.123v141.267c0,13.586-5.62,25.815-14.614,34.669v6.852 l7.025,120.694c0,10.856,8.815,19.662,19.662,19.662c4.592,0,13.029,0,21.475,0c8.453,0,16.899,0,21.474,0 c10.863,0,19.662-8.806,19.662-19.662l7.025-120.694c12.889,0,7.873,0,12.889,0c10.204,0,18.468-8.265,18.468-18.484V211.641 C487.805,195.511,473.936,178.314,458.065,171.784z"
                fill={color}
            />
            <polygon
                points="407.886,210.581 421.073,197.403 421.073,180.323 395.752,180.323 395.752,198.447 "
                fill={color}
            />
            <rect x="395.744" y="160.23" width="25.344" height="11.648" fill={color} />
            <path
                d="M103.289,149.084c23.249,0,42.094-18.854,42.094-42.11c0-23.256-18.845-42.11-42.094-42.11 c-23.28,0-42.102,18.853-42.102,42.11C61.187,130.231,80.009,149.084,103.289,149.084z"
                fill={color}
            />
            <path
                d="M137.26,188.855l-33.123,33.123l-50.202-50.194c-15.87,6.53-29.74,23.727-29.74,39.858v141.518 c0,10.22,8.265,18.484,18.468,18.484c5.015,0,0,0,12.888,0l7.026,120.694c0,10.856,8.798,19.662,19.661,19.662 c4.576,0,13.022,0,21.475,0c8.446,0,16.883,0,21.475,0c10.848,0,19.662-8.806,19.662-19.662l7.025-120.694v-6.852 c-8.994-8.854-14.614-21.083-14.614-34.669V188.855z"
                fill={color}
            />
            <polygon
                points="104.113,210.581 116.248,198.447 116.248,180.323 90.927,180.323 90.927,197.403 "
                fill={color}
            />
            <rect x="90.911" y="160.23" width="25.345" height="11.648" fill={color} />
        </g>
    </svg>
);

export const StarIcon = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color} stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
        </svg>
    )
}

export const StarIconOutline = ({ color = "black", strokeWidth = "2px", width = "20px", height = "20px", ml = "0", mr = "0", mt = "0", mb = "0" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={"none"} stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" style={{ marginLeft: ml, marginRight: mr, marginTop: mt, marginBottom: mb }}>
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
        </svg>
    )
}