import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SiteStyles from '../Site.module.css';
import Styles from './Footer.module.css';
import { useNavigate } from 'react-router-dom';
import TextStyles from '../TextStyles.module.css'
import { Container } from 'react-bootstrap';
import Config from '../../config.json'


const Footer = (props) => {
    let navigate = useNavigate();

    var isWhite = false;
    if (props && props.white && props.white === true) { isWhite = true }

    return (

        <div style={{ backgroundColor: isWhite ? 'black' : 'transparent', fontFamily: 'Source Sans Pro' }}>
            <Container>
                <br />


                {/* Flex Div */}
                <div className={Styles.ColumnFlex} >

                    {/* COLUMN 1 */}
                    <div className={Styles.FooterColumn} style={{}}>
                        <p className={TextStyles.Body} style={{ color: isWhite ? 'white' : 'black', marginBottom: 8, }}>© 2025 Bright Doctor Inc.</p>
                        <p className={TextStyles.Body} style={{ color: isWhite ? 'white' : 'black', margin: 0 }}>🇺🇸 Aventura, USA</p>
                        <p className={TextStyles.Body} style={{ color: isWhite ? 'white' : 'black' }}>🇨🇦 Toronto, Canada</p>
                    </div>

                    {/* COLUMN 2 */}
                    <div className={Styles.FooterColumn} style={{}}>
                        <p className={Styles.SectionHeader} style={{ color: isWhite ? 'white' : 'black' }}>RESOURCES</p>
                        <a className={TextStyles.TOS} style={{ color: isWhite ? 'white' : 'black', textDecoration: 'none', }}
                            href="/mstar">M-STAR | Medical School Directory</a>
                        <a className={TextStyles.TOS} style={{ color: isWhite ? 'white' : 'black', textDecoration: 'none', }}
                            href='/blog'>Blog</a>
                    </div>

                    {/* COLUMN 3 */}
                    <div className={Styles.FooterColumn} style={{}}>
                        <p className={Styles.SectionHeader} style={{ color: isWhite ? 'white' : 'black' }}>PRODUCTS</p>
                        <a className={TextStyles.TOS} style={{ color: isWhite ? 'white' : 'black', textDecoration: 'none', }}
                            onClick={() => { navigate('/products/medinterview') }}>MMI / Medicine Interview Question Bank</a>
                        <a className={TextStyles.TOS} style={{ color: isWhite ? 'white' : 'black', textDecoration: 'none', }}
                            onClick={() => { navigate('/products/casper') }}>CASPer® Question Bank</a>
                        <a className={TextStyles.TOS} style={{ color: isWhite ? 'white' : 'black', textDecoration: 'none', }}
                            onClick={() => { navigate('/products/aamcpreview') }}>AAMC PREview™ Question Bank</a>
                    </div>

                    {/* COLUMN 4 */}

                    <div className={Styles.FooterColumn} style={{}}>
                        <p className={Styles.SectionHeader} style={{ color: isWhite ? 'white' : 'black' }}>OTHER</p>
                        <p className={TextStyles.TOS} style={{ color: isWhite ? 'white' : 'black' }}
                            onClick={() => { navigate('/tos') }}>Terms of Service</p>
                        <p className={TextStyles.TOS} style={{ color: isWhite ? 'white' : 'black' }}
                            onClick={() => { navigate('/privacy') }}>Privacy Policy</p>
                        <a className={TextStyles.TOS} style={{ color: isWhite ? 'white' : 'black', textDecoration: 'none', }}
                            onClick={() => { navigate('/contact') }}>Contact</a>
                    </div>

                </div>

                {/* Bottom Disclosures */}
                <br />
                <br />
                <p className={TextStyles.Body} style={{ textAlign: 'left', fontSize: 'small', color: isWhite ? 'white' : 'black' }}>The Bright Doctor and its products are not affiliated with or endorsed by the Association of American Medical Colleges (AAMC) or any other company, university, professional association, or academic institution.</p>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', color: isWhite ? 'white' : 'black' }}>
                    <small style={{ marginBottom: 8 }}>†</small>
                    <p className={TextStyles.Body} style={{ textAlign: 'left', fontSize: 'small', color: isWhite ? 'white' : 'black' }}>The Bright Doctor and its products are neither associated with nor endorsed by any specific academic institution. Additionally, they are not associated with, nor endorsed by, any student-led or faculty-led organizations within any academic institutions.</p>
                </div>
                <br />
            </Container>
        </div >

    );
}

export default Footer;
